'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { AddToFavoriteFeature } from '@features/desktop/add-to-favorite/ui';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties/ui';
import { ListCardsWidgetProps } from '@widgets/desktop/list-cards/ui/list-cards.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import { useIsClient } from 'usehooks-ts';

import { Label, Skeleton } from '@/shared/ui';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './list-cards.module.scss';

export const ListCardsWidget = observer(
  ({
    className,
    topSlot = (
      <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
        Popular Items
      </Label>
    ),
    cardsVariant,
    items,
    ...props
  }: ListCardsWidgetProps) => {
    const listCardsClasses = cn(styles['list-cards'], className);
    const bottomSlotClasses = cn(
      styles['bottom-slot'],
      styles[`bottom-slot--${cardsVariant}`],
    );
    const router = useRouter();
    const isClient = useIsClient();
    const game = useStore()?.app?.getGame;

    return (
      <div className={listCardsClasses} {...props}>
        <div className={styles['top-slot']}>{topSlot}</div>

        <div className={bottomSlotClasses}>
          {isClient ? (
            Array.isArray(items) &&
            items.length > 0 &&
            items.map((item, idx) => {
              let itemReadyToCart: CartItemType = {
                ...item,
                status: 'available',
                quantity: 1,
              };

              return (
                <CardItemEntity
                  isMM2Variant={game === 'mm2'}
                  onClick={() =>
                    router.push(`/shop/${item.category}/${item?.id}`)
                  }
                  variant={cardsVariant}
                  key={`card-${idx}-${item.info.title}-${item?.info?.price?.old}`}
                  item={item.item}
                  rarity={item.rarity}
                  info={{
                    title: item?.info?.title,
                    price: {
                      old: item?.info?.price?.old,
                      current: item?.info?.price?.current,
                    },
                  }}
                  propertiesSlot={<SelectPetPropertiesFeature item={item} />}
                  tagsInfo={item?.tagsInfo}
                  topRightSlot={
                    <AddToFavoriteFeature
                      variant={
                        cardsVariant === 'tertiary' ? 'secondary' : 'primary'
                      }
                      id={item.id}
                    />
                  }
                  bottomSlot={<AddToCartFeature item={itemReadyToCart} />}
                />
              );
            })
          ) : (
            <>
              {Array.isArray(items) &&
                items.length > 0 &&
                items.map((_, idx) => (
                  <Skeleton
                    key={`skeleton-list-cards-${idx}`}
                    className={cn(
                      styles['list-card-skeleton'],
                      styles[`variant--${cardsVariant}`],
                    )}
                  />
                ))}
            </>
          )}
        </div>
      </div>
    );
  },
);
