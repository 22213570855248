import {
  AGE_FILTERS,
  CATEGORIES_MM2_FILTERS,
  CATEGORY_FILTERS,
  HOT_SALE_FILTERS,
  NEW_FILTERS,
  PRICE_FILTERS,
  PROPERTIES_FILTERS,
  RARITY_FILTERS,
  RARITY_MM2_FILTERS,
} from '@features/desktop/filter-shop-items/ui/constants';

import { SORTING_FILTERS_BY_VARIANT } from '@/features/drop-list-sort/items';
import { Filter } from '@/shared/types/common';

import {
  SIDEBAR_CATEGORIES_FILTERS,
  TRANSPORT_FILTERS,
} from './sidebar-filters';

export const SEARCH_FILTER: Filter[] = [
  {
    filter: '',
    group: 'search',
    label: '',
  },
];

export const ALL_FILTERS: Filter[] = [
  ...RARITY_FILTERS,
  ...NEW_FILTERS,
  ...CATEGORY_FILTERS,
  ...PROPERTIES_FILTERS,
  ...AGE_FILTERS,
  ...HOT_SALE_FILTERS,
  ...TRANSPORT_FILTERS,
  ...SIDEBAR_CATEGORIES_FILTERS.bottomSlot,
  ...SORTING_FILTERS_BY_VARIANT['shop'],
  ...SORTING_FILTERS_BY_VARIANT['favorite'],
  ...PRICE_FILTERS,
  ...SEARCH_FILTER,
  ...RARITY_MM2_FILTERS,
  ...CATEGORIES_MM2_FILTERS,
];
