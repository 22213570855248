'use client';
import { useTimer } from '@hooks/client';
import { validationSchema } from '@widgets/desktop/settings/ui/validation';
import cn from 'clsx';
import { getCookie, setCookie } from 'cookies-next';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { CHANGE_PASSWORD, VERIFY_EMAIL } from '@/shared/api/endpoints';
import { useUser } from '@/shared/api/user/useUser';
import usersApi from '@/shared/api/usersApi';
import {
  Button,
  Divider,
  Illustration,
  Skeleton,
  Typography,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { MotionWrapper } from '@/shared/ui/desktop/motion-wrapper';
import { AuthInfo } from '@/store/authorization/types';
import { useStore } from '@/store/context';

import styles from './settings.module.scss';

export const SettingsWidget = () => {
  return (
    <div className={styles.container}>
      <SettingsWidget.Header />
      <SettingsWidget.EmailBlock />
      <SettingsWidget.PasswordBlock />
    </div>
  );
};

// eslint-disable-next-line react/display-name
SettingsWidget.Header = () => {
  return (
    <div className={styles.header}>
      <Typography className={styles['header__title']}>Profile</Typography>
      <Typography className={styles['header__description']}>
        Edit your profile!
      </Typography>
      <Divider direction={'horizontal'} />
    </div>
  );
};

// eslint-disable-next-line react/display-name
SettingsWidget.EmailBlock = observer(() => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const notificationStore = useStore().notifications;
  const { user } = useUser();
  const [initialTimer, setInitialTimer] = useState(0);

  const {
    timer,
    count,

    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration: initialTimer,
  });

  const checkIsRequestAvailable = () => {
    const rateLimit = getCookie('rate-limit');

    if (rateLimit) {
      const secondesLeft = Math.ceil((Number(rateLimit) - Date.now()) / 1000);
      setInitialTimer(secondesLeft);
    }
  };

  const saveRateLimit = (rate: string | null) => {
    const secondsLeft = Math.ceil((Number(rate) - Date.now()) / 1000);
    setCookie('rate-limit', rate, { maxAge: secondsLeft });
    setInitialTimer(secondsLeft);
  };

  const handleLinkSent = async () => {
    try {
      const response = await usersApi.get(VERIFY_EMAIL);
      notificationStore?.addNotification({
        variant: 'success',
        title: 'The email has been sent',
        subtitle: 'Follow the link inside the email to confirm your email',
        delay: 5,
        id: 'email-successfully-sent',
      });
      const rateLimit = response.headers.get('x-rate-limit-next');
      saveRateLimit(rateLimit);
    } catch (err: any) {
      const rateLimit = err.response.headers.get('x-rate-limit-next');
      saveRateLimit(rateLimit);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    checkIsRequestAvailable();
  }, []);

  if (user && !user.email) return null;

  return user ? (
    <div className={styles.content}>
      <InputAuth
        iconName={'inbox'}
        value={user?.email || ''}
        placeholder="Email"
        onChange={() => {}}
        withErrorMessage={false}
        className={styles['input']}
        iconLeft={<SettingsWidget.EmailStatusIcon />}
      />
      {!user?.isEmailVerified && (
        <Button
          onClick={handleLinkSent}
          variant={'quaternary'}
          className={styles.btn}
          disabled={count !== 60 && count !== 0}
          text={
            count !== 60 && count !== 0
              ? `Resend the letter in ${timer.substring(3)}`
              : 'Confirm email'
          }
        />
      )}
    </div>
  ) : (
    <Skeleton className={styles.skeleton} />
  );
});

SettingsWidget.EmailStatusIcon = observer(() => {
  const { user } = useUser();
  const [showTooltip, setShowTooltip] = useState(false);

  const verified = user?.isEmailVerified;

  const tooltipClasses = cn(styles.tooltip, {
    [styles['tooltip-long']]: !verified,
  });

  return (
    <div
      className={styles['status-icon']}
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Illustration
        name={verified ? 'check-square' : 'danger-triangle'}
        style={{ color: verified ? '#40BB18' : '#EB3C3C' }}
        spriteName={'icons'}
      />
      <AnimatePresence>
        {showTooltip && (
          <MotionWrapper className={tooltipClasses}>
            <div className={styles['tooltip_body']}>
              <Typography
                size={'base'}
                weight={'semi-bold'}
                className={styles['tooltip_text']}
              >
                {verified ? 'Verified' : 'Verified mail'}
              </Typography>
            </div>
          </MotionWrapper>
        )}
      </AnimatePresence>
    </div>
  );
});

// eslint-disable-next-line react/display-name
SettingsWidget.PasswordBlock = observer(() => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changePasswordOpened, setChangePasswordOpened] = useState(false);
  const { user } = useUser();

  if (changePasswordOpened) {
    return (
      <SettingsWidget.PasswordOpened
        close={() => setChangePasswordOpened(false)}
      />
    );
  }

  return user ? (
    <div className={styles.content}>
      <InputAuth
        iconName={'lock-password'}
        value={'dsadasdasdas'}
        placeholder="Password"
        withErrorMessage={false}
        securityInput
        onChange={() => {}}
        showEye={false}
        filledDisabled
        className={styles['input']}
      />
      <Button
        onClick={() => setChangePasswordOpened(true)}
        variant={'quaternary'}
        className={styles.btn}
        text={'Change password'}
      />
    </div>
  ) : (
    <Skeleton className={styles.skeleton} />
  );
});

// eslint-disable-next-line react/display-name
SettingsWidget.PasswordOpened = observer(({ close }: { close: () => void }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useStore();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handlePasswordChanged = () => {
    close();
    store.notifications?.addNotification({
      variant: 'success',
      title: 'Successfully!',
      subtitle: 'Password successfully changed!',
      delay: 5,
      id: 'email-successfully-sent',
    });
  };

  const onSubmit = async () => {
    try {
      await usersApi.put<AuthInfo>(CHANGE_PASSWORD, {
        oldPassword: formik.values.oldPassword,
        newPassword: formik.values.newPassword,
        confirmPassword: formik.values.confirmPassword,
      });
      handlePasswordChanged();
    } catch (err: any) {
      formik.setFieldError('oldPassword', 'The old password is incorrect');
    }
  };

  return (
    <div className={styles.content}>
      <InputAuth
        iconName={'lock-password'}
        value={formik.values.oldPassword}
        onChange={formik.handleChange('oldPassword')}
        onBlur={formik.handleBlur('oldPassword')}
        onInput={() => formik.setFieldTouched('oldPassword')}
        placeholder="Old password"
        withErrorMessage={false}
        securityInput
        errorMessage={
          formik.touched.oldPassword ? formik.errors.oldPassword : ''
        }
      />
      <InputAuth
        iconName={'lock-password'}
        value={formik.values.newPassword}
        onChange={formik.handleChange('newPassword')}
        onBlur={formik.handleBlur('newPassword')}
        onInput={() => formik.setFieldTouched('newPassword')}
        placeholder="New password"
        withErrorMessage={false}
        securityInput
        errorMessage={
          formik.touched.newPassword ? formik.errors.newPassword : ''
        }
      />
      <InputAuth
        iconName={'lock-password'}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange('confirmPassword')}
        onBlur={formik.handleBlur('confirmPassword')}
        onInput={() => formik.setFieldTouched('confirmPassword')}
        placeholder="Confirm password"
        withErrorMessage={false}
        securityInput
        errorMessage={
          formik.touched.confirmPassword ? formik.errors.confirmPassword : ''
        }
      />
      <Button
        onClick={onSubmit}
        variant={'quaternary'}
        className={styles.btn}
        text={'Change password'}
        disabled={!formik.isValid}
      />
    </div>
  );
});
