'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { AddToFavoriteFeature } from '@features/desktop/add-to-favorite/ui';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { ReactNode } from 'react';

import {
  DivElementType,
  GlobalItem,
  GlobalMM2Item,
} from '@/shared/types/common';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './content.module.scss';

export type Items = {
  items: (GlobalItem | GlobalMM2Item)[];
};
export type ContentWidgetItemsBlockProps = DivElementType & {
  headerSlot?: ReactNode;
  emptySlot?: ReactNode;
} & Items;

export const ContentWidget = observer(
  ({ headerSlot, emptySlot, items }: ContentWidgetItemsBlockProps) => {
    const router = useRouter();
    const game = useStore()?.app.getGame;

    if (!items.length && emptySlot) {
      return emptySlot;
    }

    return (
      <div className={styles['items-block']}>
        {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
        <div className={styles['items']}>
          {items?.map((item, idx) => {
            let itemReadyToCart: CartItemType = {
              ...item,
              status: 'available',
              quantity: 1,
            };

            return (
              <CardItemEntity
                isMM2Variant={game === 'mm2'}
                onClick={() =>
                  router.push(`/shop/${item.category}/${item?.id}`)
                }
                key={`${item?.id}${idx}`}
                variant={'tertiary'}
                item={item.item}
                rarity={item.rarity}
                info={{
                  title: item?.info?.title,
                  price: {
                    old: item?.info?.price?.old,
                    current: item?.info?.price?.current as string | number,
                  },
                }}
                propertiesSlot={<SelectPetPropertiesFeature item={item} />}
                tagsInfo={item?.tagsInfo}
                topRightSlot={
                  <AddToFavoriteFeature variant={'tertiary'} id={item.id} />
                }
                bottomSlot={<AddToCartFeature item={itemReadyToCart} />}
              />
            );
          })}
        </div>
      </div>
    );
  },
);
